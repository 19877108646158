import { ChevronLeftRounded } from "@mui/icons-material";

const BreadcrumbBackButton = ({ onClick }) => {
  return (
    <button
      className="flex items-center justify-center w-10 h-10 rounded-full hover:opacity-90 transition duration-300"
      style={{ backgroundColor: "#5131d7" }}
      onClick={onClick}
    >
      <ChevronLeftRounded className="text-white" fontSize="large" />
    </button>
  );
};

export default BreadcrumbBackButton;
